import React, { useState, useEffect, Fragment } from "react"
import Link from "gatsby-link"
import { useForm } from "react-hook-form"
import axios from 'axios';
import { navigate } from 'gatsby';
import isLoggedIn from '../services/auth';
import prepareAxiosConfig from "../services/loginAxiosConfig";
import { BsEye, BsEyeSlash } from "react-icons/all";
import clearLocalStorage from "../services/clearLocalStorage";
import SEO from "../components/seo"

import "../components/layout/layout.scss"
import * as styles from "../scss/loginPage.module.scss"
import * as btnStyles from "../components/buttons/buttons.module.scss"

import * as formStyles from "../scss/form.module.scss"
import Logo from "../images/logo.svg"


const LoginPage = () => {
    const { register, handleSubmit, watch, errors } = useForm()

    const [loggedIn, setLoggedIn] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validServerRequest, setValidServerRequest] = useState(true);
    const [showPass, setShowPass] = useState(true);
    const [showInvCred, setShowInvCred] = useState(false);
        

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            if (loggedIn) navigate('/modules');
            if (!loggedIn) {
                clearLocalStorage();
            }
        })
    })

   
    const doSubmit = ({email,password}) => {
        
        const config = prepareAxiosConfig(email,password);

        axios(config)
            .then((response)=> {
                console.log("The axios login response",response);
                if(response.data.c == 200 )
                {
                        localStorage.setItem("user", response.data.token);
                        let videoProgress  = JSON.stringify(response.data.videoProgress);
                        localStorage.setItem("videoProgress", videoProgress);
                         navigate('/modules/');  
                }
                else if(response.data.c == 403 )
                {
                    setShowInvCred(true); 
                }
            }
            )
            .catch(function (error) {
                console.log(error);
            });

    }

    

    return (
        <Fragment>
        <SEO
                    title="Login"
                    description = "Dein 7-Tage Detox Kurs - Alles was du brauchst, um optimal zu entgiften! Mit über 45 Videoanleitungen in HD, 7 strukturierten Tagesplänen, einem Detox-Rezeptbuch und alle notwendigen Unterlagen für eine erfolgreiche Detox-Woche."
                    keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
                    author= "spectory"
                />
        <div className={styles.loginPage}>
            
            <div className={styles.shapeFirst}></div>
            <div className={styles.shapeSecond}></div>
            <div className="container">
                <Link to="/">
                    <Logo className={styles.logo} />
                </Link>
                <div className={styles.row}>
                    <div className={styles.content}>
                        <h1>Einsteigen & Entgiften</h1>
                        <p>
                            Mithilfe meines Online Detox-Kurses erfährst du die besten
                            Praktiken, wertvolle Tipps und Schritt-für-Schritt Anleitungen, um
                            deinen Körper effektiv von Giftstoffen zu befreien.
            </p>
                        <p>
                            So ist es möglich, mit Genuss und Freude zu entgiften, sich von
                            körperlichen Beschwerden zu befreien und seine Gesundheit auch in
                            einer belastenden Umwelt zu erhalten.
            </p>
                    </div>
                    <div className={styles.form}>
                        <h2>Einloggen</h2>

                        <form className={formStyles.form} onSubmit={handleSubmit(doSubmit)}>

                            <p style={{ marginBottom: " 5px" }}>E-Mail</p>
                            <input
                                type="email"
                                name="email"
                                className={formStyles.input}
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                placeholder="mail@adresse.com"
                                ref={register({
                                    required: true,
                                    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
                                })}
                            />
                            {errors.email &&
                                <p class={formStyles.inputError} style={{ marginTop: "5px" }}>E-Mailadresse wird benötigt</p>}


                                
                            <p style={{ marginBottom: " 5px" }}>Passwort</p>
                            <div className={formStyles.passwordInput}>
                                <input
                                    type={showPass ? "password" : "text"}
                                    name="password"
                                    className={formStyles.input}
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    placeholder="************"
                                    ref={register({
                                        required: true,
                                        // pattern: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/i,
                                    })}
                                />
                                 <button type="button" onClick={() => {setShowPass(!showPass)}}>
                                 {showPass ? <BsEye /> : <BsEyeSlash />}
                                </button>
                            </div>

                            {errors.password && (
                                <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                                    Passwort ist erforderlich und mindestens acht Zeichen, mindestens
                                    ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl
                                </p>
                            )}

                            {showInvCred && (
                                <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                                    Deine Emailadresse und/oder Passwort ist/sind falsch.
                                </p>
                            )}

                            <p
                            className ={styles.forgotPassword} 
                            onClick={() => {
                                    navigate("/resetpassword")
                                }}
                                >
                                    Passwort vergessen?
                                </p>

                            <input style={{ marginTop: " 25px" }}
                                type="submit"
                                className={`${btnStyles.button} ${btnStyles.buttonBorder}`}
                                value="Anmelden"
                            />
                            {!validServerRequest && (
                                <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                                    Emailadresse und/oder Paßwort sind nicht korrekt!
                                </p>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </Fragment>
    )
}

export default LoginPage

{/* <div className={formStyles.passwordInput}>
                <input
                    type={showPass ? "password" : "text"}
                    name="password"
                    className={formStyles.input}
                    onChange={onChange}
                    value={formData.password}
                    placeholder="************"
                    ref={register({
                        required: true,
                        pattern: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/i,
                    })}
                />
                <button type="button" onClick={() => {
                    setShowPass(!showPass)
                }}>
                    {showPass ? <BsEye /> : <BsEyeSlash />}
                </button>
            </div> */}