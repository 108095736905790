const prepareAxiosConfig = (email,password) =>{

    let data = JSON.stringify({
        email,
        password
        });

    let config = {
        method: 'post',
        url: 'https://detoxme.at/wp/wp-json/siegfried/v1/auth',
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };
    
    return config;
}

export default prepareAxiosConfig;